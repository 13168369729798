import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Layouts
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { DeviceWidgetComponent } from './components/device-widget/device-widget.component';

import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';

import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { EventsListComponent } from './components/events-list/events-list.component';

@NgModule({
	declarations: [
		HeaderComponent,
		SidebarComponent,
		DeviceWidgetComponent,
		EventsListComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		FontAwesomeModule,
		FormsModule,
		SidebarModule,
		ButtonModule,
		DividerModule,
		AvatarModule,
		AvatarGroupModule,
		DropdownModule,
		SkeletonModule
	],
	exports: [
		RouterModule,
		HeaderComponent,
		SidebarComponent,
		DeviceWidgetComponent,
		EventsListComponent
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
