import { Component, Input } from '@angular/core';
import { fadeInOutTrigger } from '../../animations/fade-in-out';
@Component({
	selector: 'app-events-list',
	templateUrl: './events-list.component.html',
	styleUrls: ['./events-list.component.scss'],
	animations: [fadeInOutTrigger]
})
export class EventsListComponent {

	@Input() events: any[] = [];
	@Input() loading: boolean = false;
	@Input() limit: number | undefined;

	trackByIndex(index: number): number {
		return index;
	}
}