import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

	ngOnInit(): void {

	}

	events: any = [
		{
			name: "Test",
			created_at: "2023-12-11T08:17:55.000000Z"
		},
		{
			name: "Test",
			created_at: "2023-12-11T09:17:55.000000Z"
		},
		{
			name: "Test",
			created_at: "2023-12-11T10:17:55.000000Z"
		}
	];
}
