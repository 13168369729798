<div class="antialiased text-primary min-h-screen pb-20 md:pb-0" *ngIf="isAuthenticated">
	<app-header></app-header>

	<main class="px-2 md:px-4 h-auto pt-20 relative">
		<div class="mb-2">
			<xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
		</div>
		<router-outlet></router-outlet>
	</main>

	<div class="fixed bottom-0 left-0 right-0 h-20 bg-gray z-50 md:hidden shadow-[0_-1px_12px_0_rgba(0,0,0,0.05)] rounded-[1.25rem_1.25rem_0_0]">
		<div class="flex items-center h-full justify-around">
			<div>
				<a pButton class="p-button-text" routerLink="/dashboard" routerLinkActive #rlaBottomNavDashbaord="routerLinkActive"
					[ngClass]="{'': rlaBottomNavDashbaord.isActive ,'p-button-text': !rlaBottomNavDashbaord.isActive }">
					<fa-icon [icon]="['fas', 'home']"></fa-icon>
					<span class="ml-2">Home</span>
				</a>
			</div>
			<div>
				<a pButton class="p-button-text" routerLink="/events" routerLinkActive #rlaBottomNavEvents="routerLinkActive"
					[ngClass]="{'': rlaBottomNavEvents.isActive ,'p-button-text': !rlaBottomNavEvents.isActive }">
					<fa-icon [icon]="['fas', 'bell']"></fa-icon>
					<span class="ml-2">Events</span>
				</a>
			</div>
			<div>
				<a pButton routerLink="/devices" routerLinkActive #rlaBottomNavDevices="routerLinkActive"
					[ngClass]="{'': rlaBottomNavDevices.isActive ,'p-button-text': !rlaBottomNavDevices.isActive }">
					<fa-icon [icon]="['fas', 'list-ul']"></fa-icon>
					<span class="ml-2">Devices</span>
				</a>
			</div>
		</div>
	</div>
	<ng-template #iconTemplate>
		<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
	</ng-template>
</div>
<!-- <p-dialog header="Header" [(visible)]="showNewVersionDialog" [draggable]="false" [resizable]="false" [modal]="true" [style]="{ width: '50rem' }"
	[breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
	<p class="m-0">
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
		veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
		consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
		cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
	</p>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="showNewVersionDialog = false" label="Ok" pAutoFocus [autofocus]="true"></p-button>
	</ng-template>
</p-dialog> -->