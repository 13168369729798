import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { EventsState } from '../shared/state/events/events.state';
import { FormBuilder } from '@angular/forms';
import { GatewayDevicesState } from '../shared/state/gateway_devices/gateway_devices.state';
import { Device } from '../shared/models/device/device.model';
import { Observable, first } from 'rxjs';
import { PaginatorState } from 'primeng/paginator';
import { Events } from '../shared/state/events/events.actions';
import { DeviceEvent } from '../shared/models/device_event/device_event.model';
import { fadeInOutTrigger } from '../shared/animations/fade-in-out';
import { GatewaysEvents } from '../shared/state/gateways_events/gateways_events.actions';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss'],
	animations: [fadeInOutTrigger]
})
export class EventsComponent implements OnInit {

	@ViewChild('calendar') calendar: any;

	range: any;

	showFilterOptions: boolean = false;

	filtersApplied: number = 0;

	@Select(EventsState.getEvents) $events: Observable<any>;
	@Select(EventsState.loading) $eventsLoading: Observable<boolean>;
	@Select(EventsState.getEventsFilter) $eventsFilter: Observable<any>;
	@Select(GatewayDevicesState.getGatewayDevices) $devices: Observable<Device[]>;

	eventsLoading: boolean = false;
	events: DeviceEvent[] = [];
	filter: any;

	filters: any[] = [
		{
			id: 1,
			label: 'Motion Sensor',
			value: 'motion',
			active: false,
			disabled: false
		},
		{
			id: 2,
			label: 'Lekage Sensor',
			value: 'water',
			active: false,
			disabled: false
		},
		{
			id: 3,
			label: 'Door/Window Sensor',
			value: 'contact',
			active: false,
			disabled: false
		},
		{
			id: 4,
			label: 'Fire/Smoke Sensor',
			value: 'smoke',
			active: false,
			disabled: false
		},
		{
			id: 5,
			label: 'Gateway Online',
			value: 'gateway-online',
			active: false,
			disabled: false,
			group: [5, 6]
		},
		{
			id: 6,
			label: 'Gateway Offline',
			value: 'gateway-offline',
			active: false,
			disabled: false,
			group: [5, 6]
		},
		{
			id: 7,
			label: 'Door/Window Open',
			value: 'contact-open',
			active: false,
			disabled: false,
			group: [7, 8]
		},
		{
			id: 8,
			label: 'Door/Window Closed',
			value: 'contact-closed',
			active: false,
			disabled: false,
			group: [7, 8]
		},
		{
			id: 9,
			label: 'Motion inactive',
			value: 'motion-inactive',
			active: false,
			disabled: false,
			group: [9, 10]
		},
		{
			id: 10,
			label: 'Motion active',
			value: 'motion-active',
			active: false,
			disabled: false,
			group: [9, 10]
		},
		{
			id: 11,
			label: 'Fire/Smoke detected',
			value: 'smoke-detected',
			active: false,
			disabled: false,
			group: [11, 12]
		},
		{
			id: 12,
			label: 'Fire/Smoke inactive',
			value: 'smoke-clear',
			active: false,
			disabled: false,
			group: [11, 12]
		},
		{
			id: 13,
			label: 'Water detected',
			value: 'water-wet',
			active: false,
			disabled: false,
			group: [13, 14]
		},
		{
			id: 14,
			label: 'Water inactive',
			value: 'water-dry',
			active: false,
			disabled: false,
			group: [13, 14]
		},
		{
			id: 15,
			label: 'Gateway Events',
			value: 'gateway',
			active: false,
			disabled: false,
			group: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
		},
		{
			id: 16,
			label: 'Device Events',
			value: 'device',
			active: false,
			disabled: false
		},
		{
			id: 17,
			label: 'Panic',
			value: 'panic',
			active: false,
			disabled: false,
			group: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
		},
		{
			id: 18,
			label: 'Panic active',
			value: 'panic-active',
			active: false,
			disabled: false,
			group: [18, 19]
		},
		{
			id: 19,
			label: 'Panic canceled',
			value: 'panic-canceled',
			active: false,
			disabled: false,
			group: [18, 19]
		}
	];

	first: number = 0;

	devices: Device[];

	constructor(private fb: FormBuilder, private store: Store) { }

	ngOnInit(): void {
		this.store.dispatch(new Events.Fetch());
		this.$events.subscribe((events: DeviceEvent[]) => this.events = events);

		this.$devices.subscribe((devices: any) => this.devices = devices.map((device: Device) => {
			return device;
		}));

		this.$eventsFilter.subscribe((filter: any) => {
			if (!filter) return;
			this.filtersApplied = 0;
			console.log('filter', filter);
			if (filter.from != null && filter.to != null) {
				this.range = [new Date(filter.from), new Date(filter.to)];
				this.filtersApplied++;
			} else {
				this.range = null;
			}

			this.filter = filter;

			this.filter.f.forEach((filter: any) => {
				let index = this.filters.findIndex((f: any) => f.value == filter);
				if (index > -1) {
					this.toggleFilter(index);
				}
			});
			console.log('filters', this.filtersApplied);
			this.filtersApplied += this.filters.filter((f: any) => f.active).length;
		});

		this.$eventsLoading.subscribe((loading: boolean) => this.eventsLoading = loading);
	}

	get isRangeSet() {
		return this.range?.every((date: Date | null) => date != null);
	}

	toggleFilterOption(index: number): void {
		this.toggleFilter(index);
		this.filtersApplied = this.filters.filter((f: any) => f.active).length;
		this.updateFilter();
	}

	toggleFilter(index: number) {
		const group = this.filters[index].group;
		if (group) {
			group.forEach((g: any) => {
				const i = this.filters.findIndex((f: any) => f.id == g);
				if (i > -1) {
					this.filters[i].disabled = false;
				}
			});

			let groupActive: boolean = false;
			group.some((g: any) => {
				if (g == this.filters[index].id) {
					this.filters[index].active = !this.filters[index].active;
					if (this.filters[index].active) {
						groupActive = true;
						return true;
					}
				}
				return false;
			});

			if (groupActive) {
				group.forEach((g: any) => {
					const i = this.filters.findIndex((f: any) => f.id == g);
					if (i > -1 && !this.filters[i].active) {
						this.filters[i].disabled = true;
					}
				});
			}

		} else {
			this.filters[index].active = !this.filters[index].active;
		}
	}

	updateFilter() {
		let rawfilters = this.filters.filter((f: any) => f.active).map((f: any) => f.value);
		let filters: any[] = [];

		rawfilters.forEach(filter => {
			let f = filter.split('-');
			if (f.length > 1) {
				filters.push([f[0], f[1]]);
			} else {
				filters.push([f[0]]);
			}
		});

		let preparedFilters = filters.reduce((acc, curr) => {
			if (curr.length > 1) {
				acc.push(curr);
			} else {
				let found = filters.some(f => {
					return f.length > 1 && f[0] == curr;
				});
				if (!found) {
					acc.push(curr);
				}
			}
			return acc;
		}, []);

		preparedFilters = preparedFilters.map((pf: any) => {
			if (pf.length > 1) {
				return pf.join('-');
			} else {
				return pf[0];
			}
		});


		this.store.dispatch(new Events.SetFilter({
			...this.filter,
			f: preparedFilters
		}));

		this.store.dispatch(new Events.Fetch());
	}

	resetFilterOption(): void {
		this.filters.forEach((f: any) => {
			f.active = false;
			f.disabled = false;
		});

		this.filtersApplied = 0;

		this.store.dispatch(new Events.SetFilter({
			page: null,
			f: [],
			total: null,
			from: null,
			to: null
		}));
		this.store.dispatch(new Events.Fetch());
	}

	toggleFilterOptions(): void {
		this.showFilterOptions = !this.showFilterOptions;
	}

	onPageChange(event: PaginatorState): void {
		this.first = event.first as number;
		console.log('event', event);
		const page: number = event.page ? event.page + 1 : 1;
		this.store.dispatch(new Events.SetFilter({
			...this.filter,
			...{
				per_page: event.rows,
				page: page
			}
		}));
		this.store.dispatch(new Events.Fetch());
	}

	trackByIndex(index: number): number {
		return index;
	}

	rangeSelect(date: Date) {
		console.log('rangeSelect', this.range);

		let tzoffset = (this.range![0] || this.range![1]).getTimezoneOffset() * 60000;

		let from = this.range![0] ? new Date(this.range![0] - tzoffset).toISOString() : null;
		let to = null;
		if (this.range![1]) {
			let to_date = new Date(this.range![1]);
			to_date.setHours(23, 59, 59, 999);
			to = to_date.toISOString();
		}

		this.store.dispatch(new Events.SetFilter({ from: from, to: to }));

		if (this.isRangeSet) {
			this.filtersApplied++;
			this.store.dispatch(new Events.Fetch());
		}
	}

	onTodayClick(event: any) {
		console.log(this.range);
		let today = new Date();
		let tzoffset = today.getTimezoneOffset() * 60000;
		today.setHours(0, 0, 0, 0);
		let todayInMS = today.getTime();

		let from = new Date(todayInMS - tzoffset);
		let to = new Date(todayInMS - tzoffset);
		to.setHours(23, 59, 59, 999);

		this.range = [from, to];
		this.store.dispatch(new Events.SetFilter({ from: from, to: to }));
		this.store.dispatch(new Events.Fetch());
	}

	resetRange(event: any): void {
		event.stopPropagation();
		this.calendar.overlayVisible = false;
		this.range = [null, null];
		this.store.dispatch(new Events.SetFilter({ from: null, to: null }));
		this.filtersApplied--;
		this.store.dispatch(new Events.Fetch());
	}
}