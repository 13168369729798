import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { User } from '../shared/state/user/user.actions';
import { User as UserModel } from '../shared/models/user/user.model';
import { UserState } from '../shared/state/user/user.state';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	@Select(UserState.getUser) $user: Observable<UserModel>;
	user: UserModel;

	constructor(private store: Store) { }

	ngOnInit(): void {

		this.store.dispatch(new User.Fetch);
		this.$user.subscribe((user: UserModel) => this.user = user);

	}


}
