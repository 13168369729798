import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State, Store } from "@ngxs/store";
import { tap, finalize } from "rxjs";

import { GatewayService } from "src/app/shared/services/gateway/gateway.service";
import { Events } from "./events.actions";

import { Event } from "../../models/event/event.model";
import { GatewayState } from "../gateway/gateway.state";


export class EventsStateModel {
	events: any[]
	filter: any
	loading: boolean
}

@State<EventsStateModel>({
	name: 'eventsstate',
	defaults: {
		events: [],
		filter: {
			page: '',
			per_page: 15,
			q: '',
			f: [],
			total: '',
			from: null,
			to: null
		},
		loading: false
	}
})

@Injectable()
export class EventsState {
	constructor(private gatewayService: GatewayService, private store: Store) { }

	@Selector()
	static getEvents(state: EventsStateModel) {
		return state.events;
	}

	@Selector()
	static loading(state: EventsStateModel) {
		return state.loading;
	}

	@Selector()
	static getEventsFilter(state: EventsStateModel) {
		return state.filter;
	}

	@Action(Events.Reset)
	reset(ctx: StateContext<EventsStateModel>) {
		ctx.setState({
			events: [],
			filter: {
				page: '',
				per_page: 15,
				q: '',
				f: [],
				total: '',
				from: null,
				to: null
			},
			loading: false
		});
	}

	@Action(Events.Fetch)
	fetchEvents(ctx: StateContext<EventsStateModel>, action: Events.Fetch) {
		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};

		delete filter.total;

		filter = {
			...filter,
			f: filter.f ? filter.f.join(',') : [],
			from: filter.from == null ? '' : filter.from,
			to: filter.to == null ? '' : filter.to
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}

		const gateway = this.store.selectSnapshot(GatewayState.getSelecetdGateway);
		if (!gateway) return;

		ctx.patchState({ loading: true });

		return this.gatewayService.fetchGatewayEventsCombined(gateway.id, options).pipe(tap((returnData: any) => {
			console.log('returnData events', returnData);

			const events = returnData.data;
			const state = ctx.getState();

			ctx.patchState({
				...state,
				events: events,
				filter: {
					...state.filter,
					page: returnData.current_page,
					total: returnData.total
				}
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}

	@Action(Events.SetFilter)
	setFilter(ctx: StateContext<EventsStateModel>, action: Events.SetFilter) {
		const state = ctx.getState();
		return ctx.patchState({
			...state,
			filter: {
				...state.filter,
				...action.filter
			}
		});
	}
}
