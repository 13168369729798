import { Injectable } from "@angular/core";

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { OAuthService } from "angular-oauth2-oidc";
import { User } from "./user.actions";

export class UserStateModel {
	user: any
}

@State<UserStateModel>({
	name: 'userstate',
	defaults: {
		user: {}
	}
})

@Injectable()
export class UserState {
	constructor(private oauthService: OAuthService) { }

	@Selector()
	static getUser(state: UserStateModel) {
		return state.user;
	}

	@Action(User.Fetch)
	getUser(ctx: StateContext<UserStateModel>) {
		console.log('getUser');
		const state = ctx.getState();
		const user = {
			id: this.oauthService.getIdentityClaims()['id'],
			first_name: this.oauthService.getIdentityClaims()['name']['first'],
			last_name: this.oauthService.getIdentityClaims()['name']['last'],
			email: this.oauthService.getIdentityClaims()['email']
		}
		console.log('user', user);
		ctx.patchState({
			...state,
			user: user
		});
	}


}