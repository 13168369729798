<div class="mb-4">
	<!-- <h1 class="font-thin text-primary text-4xl mb-8">{{ gateway.name }}</h1> -->
	<h1 class="font-semibold text-2xl text-primary">Dashboard</h1>
</div>

<div>
	<div *ngIf="!gateway" class="bg-red-600 rounded-lg p-3 text-red-300 mb-4">
		No Gateway yet.
	</div>
</div>
<section class="flex flex-col gap-4 mt-5">
	<div class="flex items-center">
		<h1 class="font-semibold text-xl text-primary">System</h1>
		<div class="ml-auto">
			<a class="flex gap-2 text-sm items-center" routerLink="/settings">
				<span>Settings</span>
				<fa-icon size="xs" [icon]=" ['fas', 'chevron-right' ]"></fa-icon>
			</a>
		</div>
	</div>

	<!-- <div class="h-16 rounded-full bg-red-600 flex px-4 items-center text-white">
		<div class="flex gap-2 items-center">
			<fa-layers size="xl">
				<fa-icon class="text-white" [icon]="['fas', 'shield']"></fa-icon>
				<fa-icon class="text-red-600" [icon]="['fas', 'exclamation']" transform="shrink-6"></fa-icon>
			</fa-layers>
			<span>Alarms</span>
		</div>
		<div class="ml-auto flex items-center gap-4 px-4">
			<div class="bg-white h-9  w-9 rounded-full flex items-center justify-center">
				<span class="text-red-600 font-semibold">3</span>
			</div>
			<div>
				<a routerLink="" class="opacity-50">
					<fa-icon size="xl" [icon]="['fas', 'chevron-right']"></fa-icon>
				</a>
			</div>
		</div>
	</div> -->

	<div class="h-16 rounded-full bg-gray flex px-4 items-center">
		<div class="flex gap-2">
			<fa-icon [icon]="['fas', 'shield-alt']" size="xl"></fa-icon>
			<span>Monitoring</span>
		</div>
		<div class="bg-white h-9 rounded-full ml-auto flex items-center px-4">
			<ng-container *ngIf="gateway">
				<span *ngIf="gateway.online" class="text-green-600 font-semibold">Active</span>
				<span *ngIf="!gateway.online" class="text-red-600 font-semibold">Inactive</span>
			</ng-container>
			<ng-container *ngIf="!gateway">
				<span class="text-orange-600">State unknow</span>
			</ng-container>
		</div>
	</div>
</section>

<section class="flex flex-col gap-4 mt-5">
	<div class="flex items-center">
		<h1 class="font-semibold text-xl text-primary">Security Mode</h1>
	</div>
	<div class="h-16 rounded-full bg-gray flex px-4 items-center">
		<div class="flex gap-2">
			<fa-icon size="xl" [icon]="['fas', 'house-lock']"></fa-icon>
			<span>Security</span>
		</div>
		<div class="bg-white h-9 rounded-full ml-auto flex items-center px-4 gap-2">
			<ng-container *ngIf="gateway">
				<fa-icon *ngIf="gateway.security == 'armed_away'" [icon]="['fas', 'house-lock']"></fa-icon>
				<fa-icon *ngIf="gateway.security == 'armed_stay'" [icon]="['fas', 'user-lock']"></fa-icon>
				<fa-icon *ngIf="gateway.security == 'disarmed'" [icon]="['fas', 'unlock']"></fa-icon>

				<span class="font-semibold">
					<ng-container [ngSwitch]="gateway.security">
						<ng-container *ngSwitchCase="'armed_away'">Armed (away)</ng-container>
						<ng-container *ngSwitchCase="'armed_stay'">Armed (stay)</ng-container>
						<ng-container *ngSwitchCase="'disarmed'">Disarmed</ng-container>
					</ng-container>
				</span>
			</ng-container>
			<ng-container *ngIf="!gateway">
				<span class="text-orange-600">State unknow</span>
			</ng-container>
		</div>
	</div>

	<div class="relative">
		<div class="absolute flex justify-center items-center top-0 left-0 right-0 bottom-0" *ngIf="panic">
			<div class=" bg-white rounded-lg z-10 flex flex-col items-center p-4 gap-2 drop-shadow-lg mx-4">
				<h3 class="font-semibold">Panic Alarm Active</h3>
				<p class=" text-center">

				</p>
				<p-button label="Cancel" (onClick)="cancelPanic()"></p-button>
			</div>
		</div>
		<button #panic_button type="button"
			class="rounded-lg p-4 flex bg-red-600 items-center justify-between h-32 w-full relative overflow-hidden disabled:cursor-not-allowed disabled:opacity-50"
			(mousedown)="mousedown($event)" (mouseup)="mouseup($event)" (touchstart)="mousedown($event)" (touchend)="mouseup($event)"
			[ngClass]="{'blur-sm': panic}" [disabled]="!gateway">
			<div class="flex text-4xl items-center gap-4 font-semibold duration-300"
				[ngClass]="{'-translate-x-80 w-0': countdownShow, 'translate-x-0': !countdownShow}">
				<fa-layers>
					<fa-icon class="text-white" [icon]="['fas', 'shield']"></fa-icon>
					<fa-icon class="text-red-600" [icon]="['fas', 'exclamation']" transform="shrink-6"></fa-icon>
				</fa-layers>
				<span class="text-white">SOS</span>
			</div>
			<div *ngIf="countdownShow" class="mx-auto duration-200 self-center w-full">
				<span class="text-white text-5xl font-semibold">{{ countdown }}</span>
			</div>
			<div class="text-white flex flex-col justify-between items-center h-full duration-300"
				[ngClass]="{'translate-x-80 w-0': countdownShow, 'translate-x-0': !countdownShow}">
				<p class="text-center">
					Get Help to your current Location
				</p>
				<p class="text-center">
					Press and Hold 3 Seconds
				</p>
			</div>
		</button>
	</div>
</section>
<section class="flex flex-col gap-4 mt-5">
	<div class="flex items-center">
		<h1 class="font-semibold text-xl text-primary">Devices</h1>
		<div class="ml-auto">
			<a class="flex gap-2 text-sm items-center" [routerLink]="['/devices']" [state]="{clear: true}">
				<span>See All</span>
				<fa-icon size="xs" [icon]=" ['fas', 'chevron-right' ]"></fa-icon>
			</a>
		</div>
	</div>
	<div class="grid grid-cols-3 grid-rows-3 gap-2">
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between relative">
			<a [routerLink]="['/devices']" [state]="{clear: true}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="text-6xl">
				{{ gateway ? gateway.statistics.devices.total : 0 }}
			</div>
			<div class="leading-none break-words w-full text-center">
				Total
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between relative">
			<a [routerLink]="['/devices']" [state]="{f: 'online'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="text-6xl text-green-600">
				{{ gateway ? gateway.statistics.devices.online : 0 }}
			</div>
			<div class="leading-none break-words w-full text-center">
				Online
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between relative">
			<a [routerLink]="['/devices']" [state]="{f: 'offline'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="text-6xl text-red-600">
				{{ gateway ? gateway.statistics.devices.offline : 0 }}
			</div>
			<div class="leading-none break-words w-full text-center">
				Offline
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between overflow-hidden relative">
			<a [routerLink]="['/devices']" [state]="{f: 'water'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="relative">
				<div class="absolute bg-slate-500 rounded-full h-10 w-10 flex items-center justify-center border-8 border-gray  -right-4 -top-4">
					<span>{{ devciesFilter.water }}</span>
				</div>
				<div class="bg-white h-16 w-16 rounded-full flex items-center justify-center text-4xl">
					<fa-icon [icon]="['fas', 'droplet']"></fa-icon>
				</div>
			</div>
			<div class="leading-none break-words w-full text-center">
				Leckage
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between overflow-hidden relative">
			<a [routerLink]="['/devices']" [state]="{f: 'battery-20'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="relative">
				<div class="absolute bg-slate-500 rounded-full h-10 w-10 flex items-center justify-center border-8 border-gray  -right-4 -top-4">
					<span>{{ devciesFilter['low-battery'] }}</span>
				</div>
				<div class="bg-white h-16 w-16 rounded-full flex items-center justify-center text-4xl">
					<fa-icon [icon]="['fas', 'battery-half']"></fa-icon>
				</div>
			</div>
			<div class="leading-none break-words w-full text-center">
				Low Battery
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between overflow-hidden relative">
			<a [routerLink]="['/devices']" [state]="{f: 'contact'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="relative">
				<div class="absolute bg-slate-500 rounded-full h-10 w-10 flex items-center justify-center border-8 border-gray  -right-4 -top-4">
					<span>{{ devciesFilter.contact }}</span>
				</div>
				<div class="bg-white h-16 w-16 rounded-full flex items-center justify-center text-4xl">
					<fa-icon [icon]="['fas', 'door-open']"></fa-icon>
				</div>
			</div>
			<div class="leading-none break-words w-full text-center">
				Door/Window
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between overflow-hidden relative">
			<a [routerLink]="['/devices']" [state]="{f: 'smoke'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="relative">
				<div class="absolute bg-slate-500 rounded-full h-10 w-10 flex items-center justify-center border-8 border-gray  -right-4 -top-4">
					<span>{{ devciesFilter.smoke }}</span>
				</div>
				<div class="bg-white h-16 w-16 rounded-full flex items-center justify-center text-4xl">
					<fa-icon [icon]="['fas', 'fire-flame-curved']"></fa-icon>
				</div>
			</div>
			<div class="leading-none break-words w-full text-center">
				Smoke/Fire
			</div>
		</div>
		<div class="flex flex-col bg-gray rounded-lg h-32 p-4 items-center justify-between overflow-hidden relative">
			<a [routerLink]="['/devices']" [state]="{f: 'motion'}" class="absolute top-0 bottom-0 right-0 left-0 z-10"></a>
			<div class="relative">
				<div class="absolute bg-slate-500 rounded-full h-10 w-10 flex items-center justify-center border-8 border-gray  -right-4 -top-4">
					<span>{{ devciesFilter.motion }}</span>
				</div>
				<div class="bg-white h-16 w-16 rounded-full flex items-center justify-center text-4xl">
					<fa-icon [icon]="['fas', 'person-walking']"></fa-icon>
				</div>
			</div>
			<div class="leading-none break-words w-full text-center">
				Motion
			</div>
		</div>
	</div>
</section>

<section class="flex flex-col gap-4 mt-5">
	<div class="flex items-center">
		<h1 class="font-semibold text-xl text-primary">Events</h1>
		<div class="ml-auto">
			<a class="flex gap-2 items-center text-sm" routerLink="/events">
				<span>See all</span>
				<fa-icon size="xs" [icon]="['fas', 'chevron-right']"></fa-icon>
			</a>
		</div>
	</div>
	<ng-container *ngIf="events.length == 0 && !eventsLoading">
		<div class="flex flex-col p-3 mb-5 justify-center items-center">
			<fa-icon class="text-[64px] mb-2 opacity-20" [icon]="['fas', 'file-circle-exclamation']"></fa-icon>
			<h1 class="font-semibold text-lg">No Events yet</h1>
		</div>
	</ng-container>
	<ng-container *ngIf="events.length > 0 || eventsLoading">
		<app-events-list [events]="events" [limit]="5" [loading]="eventsLoading"></app-events-list>
	</ng-container>
</section>
<!-- <div>
	<div class="flex items-center mb-4">
		<h1 class="font-semibold text-xl text-primary">Events</h1>
		<a class="ml-auto opacity-50 text-primary" routerLink="/events">See all<fa-icon class="pl-2" [icon]="['fas', 'arrow-right']"></fa-icon></a>
	</div>
	<div *ngIf="events.length == 0 && !eventsLoading">
		<div class="flex flex-col p-3 mb-5 justify-center items-center">
			<fa-icon class="text-[64px] mb-2 opacity-20" [icon]="['fas', 'file-circle-exclamation']"></fa-icon>
			<h1 class="font-semibold text-lg">No Events yet</h1>
		</div>
	</div>
	<div *ngIf="events.length > 0 || eventsLoading">
		<app-events-list [events]="events" [limit]="5" [loading]="eventsLoading"></app-events-list>
	</div>
</div> -->

<p-dialog header="Header" [(visible)]="panicDialog" [style]="{ width: '75vw' }" [modal]="true" [draggable]="false">
	<ng-template pTemplate="header">
		<span class="text-xl font-bold">Panic</span>
	</ng-template>
	<p>
		Panic event is active! If the situation has been clarified or it was just a test, the event can be canceled.
	</p>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="panicDialog = false" label="Ok" styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog>