<div class="mb-4">
	<h1 class="font-semibold text-2xl text-primary">
		<span>
			<a routerLink="..">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
			</a>
		</span>
		Settings
	</h1>
</div>
<div class="flex flex-col gap-4">
	<div class="flex gap-4 items-center justify-center">
		<div class="bg-gray w-28 h-28 relative rounded-full border-2 border-gray">
			<div class="rounded-full overflow-hidden">
				<img src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250">
			</div>
			<div class="absolute bg-gray rounded-full h-9 w-9 flex items-center border border-gray justify-center right-0 bottom-0">
				<fa-icon size="xl" [icon]="['fas', 'camera']"></fa-icon>
			</div>
		</div>
	</div>
	<div class="flex flex-col gap-4">
		<div class="flex flex-row gap-2 items-center border-b border-gray py-4">
			<span class="opacity-50 w-20">First Name</span>
			<span>{{ user.first_name }}</span>
		</div>
		<div class="flex flex-row gap-2 items-center border-b border-gray py-4">
			<span class="opacity-50 w-20">Last Name</span>
			<span>{{ user.last_name }}</span>
		</div>
		<div class="flex flex-row gap-2 items-center border-b border-gray py-4">
			<span class="opacity-50 w-20">E-Mail</span>
			<span class="break-words">{{ user.email }}</span>
		</div>
		<div class="flex flex-row items-center border-b border-gray py-4">
			<span class="opacity-50 w-20">Phone</span>
			<span>{{user.phone }}</span>
		</div>
	</div>
	<div class="flex flex-col gap-2">
		<div>
			<h2 class="text-lg">Permissions</h2>
		</div>
		<div class="flex items-center">
			<div class="flex items-center gap-2">
				<fa-icon [icon]="['fas', 'location']"></fa-icon>
				<label for="location-permission">
					<span>Enable Location Service</span>
				</label>
			</div>
			<div class="ml-auto">
				<p-inputSwitch inputId="location-permission"></p-inputSwitch>
			</div>
		</div>
	</div>
</div>
<!--
<pre>
	{{ user | json }}
</pre> -->