import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Action, Selector, StateContext, State, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Device, State as DeviceState } from "../../models/device/device.model";
import { GatewayService } from "../../services/gateway/gateway.service";
import { GatewayDevices } from "./gateway_devices.actions";
import { tap, finalize } from "rxjs";
import { GatewayState } from "../gateway/gateway.state";

export class GatewayDevicesStateModel {
	filter: any;
	devices: any;
	loading: boolean;
}

@State<GatewayDevicesStateModel>({
	name: 'gatewaydevicesstate',
	defaults: {
		devices: [],
		filter: {
			q: '',
			f: [],
			s: []
		},
		loading: false
	}
})

@Injectable()
export class GatewayDevicesState {
	constructor(private gatewayService: GatewayService, private store: Store) { }


	@Selector()
	static getGatewayDevices(state: GatewayDevicesStateModel) {
		return state.devices ? state.devices : [];
	}

	@Selector()
	static getGatewayDevice(state: GatewayDevicesStateModel) {
		return (device_id: number) => {
			let device: any = state.devices ? state.devices.find((d: Device) => d.id == device_id) : {};
			return device;
		}
	}

	@Selector()
	static loading(state: GatewayDevicesStateModel) {
		return state.loading;
	}

	@Selector()
	static filter(state: GatewayDevicesStateModel) {
		return state.filter;
	}

	@Action(GatewayDevices.Reset)
	reset(ctx: StateContext<GatewayDevicesStateModel>) {
		ctx.setState({
			devices: [],
			filter: {
				q: '',
				f: [],
				s: []
			},
			loading: false
		});
	}

	@Action(GatewayDevices.SetFilter)
	setFilter(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.SetFilter) {
		return ctx.setState(patch<GatewayDevicesStateModel>({
			filter: action.filter
		}));
	}

	@Action(GatewayDevices.FetchForGateway)
	getGatewayDevices(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.FetchForGateway) {
		const gateway = this.store.selectSnapshot(GatewayState.getSelecetdGateway);
		if (!gateway) return;

		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};

		filter = {
			...filter,
			f: filter.f ? filter.f.join(',') : []
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}
		console.log('filter', filter);
		ctx.patchState({ loading: true });

		return this.gatewayService.fetchDevicesForGateway(gateway.id, options).pipe(tap((returnData: any) => {
			console.log('fetchDevicesForGateway', returnData);
			const devices = returnData.data.map((d: Device) => {

				d.states = d.states.map((s: DeviceState) => {
					return {
						...s,
						progress: false
					}
				});

				const attribute_list = [
					'water',
					'smoke',
					'contact',
					'motion'
				];

				let filtered_satates: DeviceState[] = d.states.filter((state: DeviceState) => {
					return state.type.smartthings_attribute && attribute_list.includes(state.type.smartthings_attribute);
				});

				let icon = 'unkown';

				if (filtered_satates.length > 1) {
					icon = 'multi-sensor';
				} else if (filtered_satates.length == 1) {
					icon = filtered_satates[0].type.smartthings_attribute;
				}

				return {
					...d,
					...{
						progress: false,
						icon: icon,
						battery: d.states.find((s: DeviceState) => s.type.smartthings_capability == 'battery' && s.type.smartthings_attribute == 'battery')
					}
				}
			});
			console.log('devices', devices);

			const state = ctx.getState();
			ctx.patchState({
				...state,
				devices: devices
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}))
	}
}