<section class="flex flex-col gap-4">
	<div class="flex items-center">
		<h1 class="font-semibold text-xl text-primary">Panic</h1>
	</div>
	<div class="flex flex-col gap-8">
		<p-timeline [value]="events" class="w-full md:w-20rem">
			<ng-template pTemplate="content" let-event>
				<div class="leading-none">
					<div>
						<span class="font-semibold">{{ event.name }}</span>
						-
						<span>{{ event.created_at | date:'HH:mm' }}</span>
					</div>
					<div>
						<span>Hallo</span>
					</div>
				</div>
			</ng-template>
		</p-timeline>
		<div>
			<p-button styleClass="w-full flex items-center justify-center gap-2 p-button-danger">
				<span>Cancel Alarm</span>
				<span class="flex bg-white h-6 w-6 items-center justify-center rounded-full text-red-600">3</span>
			</p-button>
		</div>
	</div>
</section>