import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { EventsState } from '../shared/state/events/events.state';
import { GatewayState } from '../shared/state/gateway/gateway.state';
import { Gateway } from '../shared/state/gateway/gateway.model';
import { Observable } from 'rxjs';

import { GatewayService } from '../shared/services/gateway/gateway.service';
import { GatewayDevices } from '../shared/state/gateway_devices/gateway_devices.actions';
import { GatewayDevicesState } from '../shared/state/gateway_devices/gateway_devices.state';
import { Device, State } from '../shared/models/device/device.model';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	countdown: number;
	countdownShow: boolean = false;
	time: number = 3000;
	timer: any;

	away: boolean = true;

	@Select(EventsState.getEvents) $events: Observable<any[]>;
	events: any[] = [];

	@Select(GatewayState.getSelecetdGateway) $gateway: Observable<Gateway>;
	@Select(EventsState.loading) $eventsLoading: Observable<boolean>;
	eventsLoading: boolean = false;
	gateway: Gateway;

	@Select(GatewayDevicesState.getGatewayDevices) $devices: Observable<Device[]>;
	devices: Device[];

	panicDialog: boolean = false;

	panic: boolean = false;

	devciesFilter: any = {
		water: 0,
		smoke: 0,
		contact: 0,
		motion: 0,
		'low-battery': 0
	};

	constructor(private gatewayService: GatewayService, private store: Store) { }

	ngOnInit(): void {
		this.$events.subscribe((events: any[]) => {
			if (!events) return;
			console.log('events', events);
			this.events = events.map((event: any) => {
				console.log(event);

				return event;
			});
		});

		this.$eventsLoading.subscribe((loading: boolean) => this.eventsLoading = loading);

		this.$gateway.subscribe((gateway: Gateway) => {
			this.gateway = gateway;
			if (this.gateway && Object.keys(this.gateway).length > 0) {
				this.gatewayService.fetchGatewayPanic(this.gateway.id).subscribe((result: any) => {
					console.log('result', result);
					this.panic = result.data.panic;
				});

				this.gatewayService.fetchDevicesForGateway(this.gateway.id).subscribe((response: any) => {
					const devices: Device[] = response.data;

					Object.keys(this.devciesFilter).forEach((f: string) => {
						let filtered = devices.filter((device: Device) => {
							return device.states.some((s: State) => {
								if (f == 'low-battery' && s.type.smartthings_attribute == 'battery') {
									return (s.value ? parseInt(s.value) <= 20 : s.value == null ? true : false);
								} else {
									return s.type.smartthings_attribute == f;
								}
							});
						});
						this.devciesFilter[f] = filtered.length;
					});
				});

			}
		});
	}

	onPanic() {
		console.log('Alarm');
		this.gatewayService.updateGatewayPanic(this.gateway.id, { panic: true }).subscribe((result: any) => {
			console.log('panic', result);
			this.panicDialog = true;
			this.panic = true;
		});
	}

	mousedown(event: any) {
		event.preventDefault();
		console.log('mousedown');
		this.countdownShow = true;
		if (this.timer) clearInterval(this.timer);

		this.countdown = this.time / 1000;
		this.timer = setInterval(() => {
			console.log(this.countdown);
			this.countdown -= 1;
			if (this.countdown <= 0) {
				clearInterval(this.timer);
				this.onPanic()
			}
		}, 1000);
	}

	mouseup(event: any) {
		event.preventDefault();
		console.log('mouseup');
		this.countdownShow = false;
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	cancelPanic() {
		this.gatewayService.updateGatewayPanic(this.gateway.id, { panic: false }).subscribe((result: any) => {
			console.log('cancelPanic panic', result);
			this.panic = result.data.panic_alarm;
			this.countdownShow = false;
		});
	}
}