import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

// import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { BreadcrumbModule } from 'xng-breadcrumb';


import { AppComponent } from './app.component';

import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { AuthService } from './shared/services/auth/auth.service';
import { VersionService } from './shared/services/version/version.service';
import { oauthConfig } from './oauth.config';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { versionInitializerFactory } from './version-initializer.factory';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { SharedModule } from './shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { SkeletonModule } from 'primeng/skeleton';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TimelineModule } from 'primeng/timeline';
import { InputSwitchModule } from 'primeng/inputswitch';

import { DashboardComponent } from './dashboard/dashboard.component';
import { EventsComponent } from './events/events.component';
import { EventsState } from './shared/state/events/events.state';
import { GatewayDevicesState } from './shared/state/gateway_devices/gateway_devices.state';
import { RuleState } from './shared/state/rule/rule.state';
import { GatewayState } from './shared/state/gateway/gateway.state';
import { SettingsComponent } from './settings/settings.component';
import { UserState } from './shared/state/user/user.state';
import { DevicesComponent } from './devices/devices.component';
import { EventComponent } from './event/event.component';


@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		EventsComponent,
		SettingsComponent,
		DevicesComponent,
		EventComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: ['https://api.cloud.aeotec.com'],
				sendAccessToken: true
			}
		}),
		SharedModule,
		FontAwesomeModule,
		BreadcrumbModule,
		ButtonModule,
		CalendarModule,
		DividerModule,
		DropdownModule,
		PaginatorModule,
		SkeletonModule,
		InputTextModule,
		DialogModule,
		TimelineModule,
		InputSwitchModule,
		NgxsModule.forRoot([EventsState, GatewayDevicesState, RuleState, GatewayState, UserState]),
		NgxsStoragePluginModule.forRoot({
			key: [EventsState, GatewayDevicesState, RuleState, GatewayState, UserState]
		})
	],
	providers: [
		{ provide: AuthConfig, useValue: oauthConfig },
		{ provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
		{ provide: APP_INITIALIZER, useFactory: versionInitializerFactory, deps: [VersionService], multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
