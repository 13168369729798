import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { Device, State } from '../shared/models/device/device.model';
import { GatewayDevicesState } from '../shared/state/gateway_devices/gateway_devices.state';
import { fadeInOutTrigger } from '../shared/animations/fade-in-out';
import { GatewayDevices } from '../shared/state/gateway_devices/gateway_devices.actions';

@Component({
	selector: 'app-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss'],
	animations: [fadeInOutTrigger]
})
export class DevicesComponent implements OnInit {

	$gatewayDevices: Observable<Device[]> = new Observable();
	devices: Device[] = [];

	@Select(GatewayDevicesState.loading) $devicesLoading: Observable<boolean>;
	devicesLoading: boolean;

	@Select(GatewayDevicesState.filter) $devicesFilter: Observable<any>;
	devicesFilter: any;

	filters: any[] = [
		{
			id: 1,
			label: 'Motion Sensor',
			value: 'motion',
			active: false,
			disabled: false
		},
		{
			id: 2,
			label: 'Lekage Sensor',
			value: 'water',
			active: false,
			disabled: false
		},
		{
			id: 3,
			label: 'Door/Window Sensor',
			value: 'contact',
			active: false,
			disabled: false
		},
		{
			id: 4,
			label: 'Fire/Smoke Sensor',
			value: 'smoke',
			active: false,
			disabled: false
		},
		{
			id: 5,
			label: 'Online',
			value: 'online',
			active: false,
			disabled: false,
			group: [5, 6]
		},
		{
			id: 6,
			label: 'Offline',
			value: 'offline',
			active: false,
			disabled: false,
			group: [5, 6]
		},
		{
			id: 7,
			label: 'Open',
			value: 'contact-open',
			active: false,
			disabled: false,
			group: [7, 8]
		},
		{
			id: 8,
			label: 'Closed',
			value: 'contact-closed',
			active: false,
			disabled: false,
			group: [7, 8]
		},
		{
			id: 9,
			label: 'Motion inactive',
			value: 'motion-inactive',
			active: false,
			disabled: false,
			group: [9, 10]
		},
		{
			id: 10,
			label: 'Motion detected',
			value: 'motion-active',
			active: false,
			disabled: false,
			group: [9, 10]
		},
		{
			id: 11,
			label: 'Fire/Smoke inactive',
			value: 'smoke-clear',
			active: false,
			disabled: false,
			group: [11, 12]
		},
		{
			id: 12,
			label: 'Fire/Smoke detected',
			value: 'smoke-detected',
			active: false,
			disabled: false,
			group: [11, 12]
		},
		{
			id: 13,
			label: 'Water inactive',
			value: 'water-dry',
			active: false,
			disabled: false,
			group: [13, 14]
		},
		{
			id: 14,
			label: 'Water detected',
			value: 'water-wet',
			active: false,
			disabled: false,
			group: [13, 14]
		},
		{
			id: 15,
			label: 'Battery',
			value: 'battery',
			active: false,
			disabled: false,
			group: [15, 16]
		},
		{
			id: 16,
			label: 'Low Battery',
			value: 'battery-20',
			active: false,
			disabled: false,
			group: [15, 16]
		}
	];

	showFilterOptions: boolean = false;
	showExportOptions: boolean = false;

	filtersApplied: number = 0;

	constructor(private store: Store, private router: Router) { }

	ngOnInit(): void {

		if (history.state.f || history.state.clear) {
			let f = history.state.clear ? null : history.state.f;
			this.store.dispatch(new GatewayDevices.SetFilter({ ...this.devicesFilter, f: f ? [f] : [] }));
			this.store.dispatch(new GatewayDevices.FetchForGateway());
		}

		this.$gatewayDevices = this.store.select(GatewayDevicesState.getGatewayDevices).pipe(
			map((devices: Device[]) => devices.filter((d: Device) => d.states.length > 0))
		);

		this.$devicesFilter.subscribe((filter: any) => {
			if (!filter) return;
			this.devicesFilter = filter;
			console.log(this.devicesFilter);
			this.devicesFilter.f.forEach((filter: any) => {
				let found = this.filters.find((f: any) => f.value == filter);
				if (found) {
					found.active = true;
				}
			});
			this.filtersApplied = this.filters.filter((f: any) => f.active).length;
		});

		this.$gatewayDevices.subscribe((devices: Device[]) => this.devices = devices);
		this.$devicesLoading.subscribe((loading: boolean) => this.devicesLoading = loading);
	}
	toggleFilterOption(index: number): void {
		this.toggleFilter(index);
		this.filtersApplied = this.filters.filter((f: any) => f.active).length;
		this.updateFilter();
	}

	toggleFilter(index: number): void {
		const group = this.filters[index].group;
		if (group) {
			group.forEach((g: any) => {
				const i = this.filters.findIndex((f: any) => f.id == g);
				if (i > -1) {
					this.filters[i].disabled = false;
				}
			});

			let groupActive: boolean = false;
			group.forEach((g: any) => {
				if (g == this.filters[index].id) {
					this.filters[index].active = !this.filters[index].active;
					if (this.filters[index].active) {
						groupActive = true;
					}
				}
			});

			if (groupActive) {
				group.forEach((g: any) => {
					const i = this.filters.findIndex((f: any) => f.id == g);
					if (i > -1 && !this.filters[i].active) {
						this.filters[i].disabled = true;
					}
				});
			}

		} else {
			this.filters[index].active = !this.filters[index].active;
		}
	}
	updateFilter() {
		let rawfilters = this.filters.filter((f: any) => f.active).map((f: any) => f.value);
		let filters: any[] = [];

		rawfilters.forEach(filter => {
			let f = filter.split('-');
			if (f.length > 1) {
				filters.push([f[0], f[1]]);
			} else {
				filters.push([f[0]]);
			}
		});

		let preparedFilters = filters.reduce((acc, curr) => {
			if (curr.length > 1) {
				acc.push(curr);
			} else {
				let found = filters.some(f => {
					return f.length > 1 && f[0] == curr;
				});
				if (!found) {
					acc.push(curr);
				}
			}
			return acc;
		}, []);

		preparedFilters = preparedFilters.map((pf: any) => {
			if (pf.length > 1) {
				return pf.join('-');
			} else {
				return pf[0];
			}
		});

		this.store.dispatch(new GatewayDevices.SetFilter({
			...this.devicesFilter,
			f: preparedFilters
		}));

		this.store.dispatch(new GatewayDevices.FetchForGateway());
	}

	resetFilterOption(): void {
		this.filters.forEach((f: any) => {
			f.active = false;
			f.disabled = false;
		});
		this.filtersApplied = 0;

		this.store.dispatch(new GatewayDevices.SetFilter({
			...this.devicesFilter,
			f: []
		}));

		this.store.dispatch(new GatewayDevices.FetchForGateway());
	}

	toggleFilterOptions(): void {
		this.showFilterOptions = !this.showFilterOptions;
	}

	trackByIndex(index: number): number {
		return index;
	}
}
