import { Injectable } from "@angular/core";
import { Action, Selector, StateContext, State, Store } from "@ngxs/store";
import { updateItem, patch } from "@ngxs/store/operators";
import { tap, finalize } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';


import { GatewayService } from "src/app/shared/services/gateway/gateway.service";
import { Gateways } from './gateway.actions';
import { Gateway } from './gateway.model';


export class GatewayStateModel {
	filter: any
	gateways: any
	selectedGateway: any
	loading: boolean
}

@State<GatewayStateModel>({
	name: 'gatewaystate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '',
			f: []
		},
		gateways: [],
		selectedGateway: {},
		loading: false
	}
})

@Injectable()
export class GatewayState {
	constructor(private gatewayService: GatewayService, private store: Store) { }

	@Selector()
	static currentPage(state: GatewayStateModel): number {
		return state.filter.page;
	}

	@Selector()
	static itemsOnPage(state: GatewayStateModel): number {
		return state.filter.per_page;
	}

	@Selector()
	static query(state: GatewayStateModel): string {
		return state.filter.q;
	}

	@Selector()
	static total(state: GatewayStateModel): string {
		return state.filter.total;
	}

	@Selector()
	static filter(state: GatewayStateModel): string {
		return state.filter;
	}

	@Selector()
	static getGateways(state: GatewayStateModel) {
		return state.gateways;
	}

	@Selector()
	static getGateway(state: GatewayStateModel) {
		return state.gateways;
		// return (id: number) => {
		// 	return state.gateways.find((g: Gateway) => {
		// 		return g.id == id
		// 	});
	}


	@Selector()
	static getSelecetdGateway(state: GatewayStateModel) {
		return state.selectedGateway;
	}

	@Action(Gateways.Reset)
	reset(ctx: StateContext<GatewayStateModel>) {
		ctx.setState({
			filter: {
				page: '',
				per_page: '',
				q: '',
				total: '',
				f: []
			},
			gateways: [],
			selectedGateway: {},
			loading: false
		});
	}

	@Action(Gateways.SelectGateway)
	selectGateway(ctx: StateContext<GatewayStateModel>, action: Gateways.SelectGateway) {
		const state = ctx.getState();
		console.log('sate.gateway', state, action);
		const gateway = state.gateways.find((gateway: Gateway) => gateway.id == action.id);

		ctx.patchState({
			...state,
			selectedGateway: gateway
		});
	}

	@Action(Gateways.SetFilter)
	setFilter(ctx: StateContext<GatewayStateModel>, action: Gateways.SetFilter) {
		return ctx.setState(patch<GatewayStateModel>({
			filter: action.filter
		}));
	}

	@Selector()
	static loading(state: GatewayStateModel) {
		return state.loading;
	}

	@Action(Gateways.FetchAll)
	getGateways(ctx: StateContext<GatewayStateModel>) {
		const state = ctx.getState();
		const filter = state.filter;
		delete filter.total;

		const options = {
			params: new HttpParams({
				fromObject: state.filter as any
			})
		}
		const selecetedGateway = this.store.selectSnapshot(GatewayState.getSelecetdGateway);

		ctx.patchState({ loading: true });

		return this.gatewayService.fetchGateways(options).pipe(tap((returnData: any) => {
			const state = ctx.getState();

			let gateways = returnData.data;

			gateways = gateways.map((gateway: Gateway) => {
				console.log('gateway', gateway);
				let offline = (gateway.statistics.devices.total && gateway.statistics.devices.online) ? gateway.statistics.devices.total - gateway.statistics.devices.online : 0;
				gateway.statistics.devices.offline = offline;
				return gateway;
			});

			let gateway = gateways[0];
			if (selecetedGateway) {
				let found = returnData.data.find((gateway: Gateway) => gateway.id == selecetedGateway.id);
				if (found) {
					gateway = found;
				}
			}

			ctx.patchState({
				...state,
				gateways: gateways,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total
				},
				selectedGateway: gateway
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}

	@Action(Gateways.Update)
	updateGateway(ctx: StateContext<GatewayStateModel>, action: Gateways.Update) {
		return ctx.setState(patch({
			gateways: updateItem((gateway: Gateway) => gateway.id == action.gateway_id, patch({ ...action.data }))
		}));
	}
}