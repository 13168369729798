export namespace GatewaysEvents {
	export class FetchAll {
		static readonly type = '[GatewaysEvents] Fetch all';
	}

	export class SetFilter {
		static readonly type = '[GatewaysEvents] Set Filter';
		constructor(public filter: any) { }
	}

	export class Reset {
		static readonly type = '[GatewaysEvents] Reset';
		constructor() { }
	}
}