<div class="mb-4">
	<h1 class="font-semibold text-2xl text-primary">
		<span>
			<a routerLink="..">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
			</a>
		</span>
		Events
	</h1>
</div>
<section class="flex flex-col gap-4">
	<div class="flex">
		<div class="ml-auto flex items-center gap-2">
			<p-button (onClick)="toggleFilterOptions()" styleClass="p-button-sm {{!showFilterOptions ? 'p-button-text' : ''}}">
				<fa-icon [icon]="['fas', 'filter']"></fa-icon>
				<span class="ml-2">Filter</span>
			</p-button>
			<div class="flex gap-2" *ngIf="filtersApplied > 0">
				<p-button (onClick)="resetFilterOption()" styleClass="p-button-text p-button-sm">
					<span>{{ filtersApplied }}</span>
					<span class="px-2">selected</span>
					<fa-icon [icon]="['fas', 'close']"></fa-icon>
				</p-button>
			</div>
		</div>
	</div>
	<div class="rounded-lg border border-gray p-2 md:p-4 flex gap-4 flex-col" *ngIf="!showFilterOptions">
		<div class="flex-grow min-w-0">
			<h1 class="font-semibold">Filter</h1>
			<h3 class="text-xs">Filtering events</h3>
		</div>
		<div class="flex gap-2 flex-wrap flex-grow min-w-0">
			<button class="flex h-9 rounded-full border px-4 gap-2 items-center disabled:opacity-50 text-sm md:text-base"
				[ngClass]="{'border-blue-600 bg-blue-200 text-blue-600': filter.active, 'border-gray': ! filter.active}"
				*ngFor="let filter of filters; trackBy:trackByIndex; let i = index" (click)="toggleFilterOption(i)" [disabled]="filter.disabled">
				<fa-icon *ngIf="filter.active" [icon]="['fas', 'check']"></fa-icon>
				<span>{{ filter.label }}</span>
			</button>
		</div>
		<div class="flex-grow min-w-0">
			<p-calendar #calendar [(ngModel)]="range" [showIcon]="true" [readonlyInput]="true" styleClass="w-full" selectionMode="range"
				(onSelect)="rangeSelect($event)" [touchUI]="true">
				<ng-template pTemplate="triggericon">
					<fa-icon [icon]="['fas', 'calendar']"></fa-icon>
				</ng-template>
				<ng-template pTemplate="footer">
					<div class="p-datepicker-buttonbar">
						<p-button styleClass="p-button-text" (onClick)="onTodayClick($event)">Today</p-button>
						<p-button styleClass="p-button-text" (onClick)="resetRange($event)">Clear</p-button>
					</div>
				</ng-template>
			</p-calendar>
		</div>
	</div>
</section>
<div *ngIf="events.length == 0 && !eventsLoading" @fadeInOut>
	<div class="flex flex-col p-3 mb-5 justify-center items-center">
		<fa-icon class="text-[64px] mb-2 opacity-20" [icon]="['fas', 'file-circle-exclamation']"></fa-icon>
		<h1 class="font-semibold text-lg">No Events yet</h1>
	</div>
</div>
<div *ngIf="events.length > 0">
	<div>
		<p-paginator [totalRecords]="filter.total" (onPageChange)="onPageChange($event)" [first]="first" [rows]="filter.per_page"
			[pageLinkSize]="3"></p-paginator>
	</div>
	<app-events-list [events]="events" [loading]="eventsLoading"></app-events-list>
	<div>
		<p-paginator [totalRecords]="filter.total" (onPageChange)="onPageChange($event)" [first]="first" [rows]="filter.per_page"
			[pageLinkSize]="3"></p-paginator>
	</div>
</div>