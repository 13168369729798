export namespace Events {
	export class Fetch {
		static readonly type = '[Events] Fetch all';
		constructor() { }
	}

	export class SetFilter {
		static readonly type = '[Events] Set Filter';
		constructor(public filter: any) { }
	}

	export class Reset {
		static readonly type = '[Events] Reset';
		constructor() { }
	}
}