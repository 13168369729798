export namespace Rules {
	export class FetchAll {
		static readonly type = '[Rule] Fetch all';
	}

	export class FetchForGateway {
		static readonly type = '[Rule] Fetch for Gateway';
	}

	export class SetFilter {
		static readonly type = '[Rule] Set Filter';
		constructor(public filter: any) { }
	}

	export class Reset {
		static readonly type = '[Rule] Reset';
	}
}