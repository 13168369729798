<div class="flex flex-col gap-4">
	<ng-container *ngIf="loading">
		<div class="h-20 md:h-16 rounded-full bg-gray flex px-5 py-2 items-center gap-4" *ngFor="let i of [].constructor(5)" @fadeInOut>
			<p-skeleton size="2rem" shape="circle"></p-skeleton>
			<div class="flex flex-col gap-1">
				<p-skeleton height=".75rem" width="6rem"></p-skeleton>
				<p-skeleton height="1rem" width="10rem"></p-skeleton>
				<p-skeleton height=".75rem" width="8rem"></p-skeleton>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!loading">
		<ng-container *ngFor="let event of events | slice:0:limit; trackBy: trackByIndex">
			<ng-container *ngIf="event.gatewayEvent_event != 'panic'">
				<div class="h-20 md:h-16 rounded-full bg-gray flex px-5 py-2 items-center gap-4 overflow-hidden" @fadeInOut [ngSwitch]="event.type">
					<ng-container *ngSwitchCase="'gatewayEvent'" [ngSwitch]="event.gatewayEvent_target">
						<div class="flex gap-2 text-2xl">
							<fa-icon [icon]="['fas', 'house']"></fa-icon>
						</div>
						<ng-container *ngSwitchCase="'gateway'" [ngSwitch]="event.gatewayEvent_event">
							<div class="flex flex-col gap-1">
								<div class="text-xs opacity-50 leading-none">
									{{ event.created_at | date:'HH:mm - dd.MM.yy' }}
								</div>
								<div class="leading-none">
									Gateway {{ event.gatewayEvent_new_value }}
								</div>
								<div class="text-xs leading-none">

								</div>
							</div>
							<div class="ml-auto text-2xl opacity-50">

							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'device'">
							<div class="flex flex-col gap-1">
								<div class="text-xs opacity-50 leading-none">
									{{ event.created_at | date:'HH:mm - dd.MM.yy' }}
								</div>
								<div class="leading-none">
									<ng-container [ngSwitch]="event.gatewayEvent_event">
										<span *ngSwitchCase="'renamed'">
											Device
											<span class="font-medium">
												"{{ event.gatewayEvent_old_value ? event.gatewayEvent_old_value : '-' }}"
											</span>
											name changed to
											<span class="font-medium">
												"{{ event.gatewayEvent_new_value }}"
											</span>
										</span>
										<span *ngSwitchCase="'added'">
											<span class="font-medium">
												{{ event.device_name ? event.device_name : event.device_third_party_name ?
												event.device_third_party_name : '-' }}
											</span>
											added
										</span>
										<span *ngSwitchCase="'removed'">
											<span class="font-medium">
												{{ event.device_name ? event.device_name : event.device_third_party_name ?
												event.device_third_party_name : '-' }}
											</span>
											removed
										</span>
										<span *ngSwitchCase="'moved'">
											Device
											<span class="font-medium">
												{{ event.device_name ? event.device_name : event.device_third_party_name ?
												event.device_third_party_name : '-' }}
											</span>
											moved from
											<span class="font-medium">
												{{ event.gatewayEvent_old_value }}
											</span>
											to
											<span class="font-medium">
												{{ event.gatewayEvent_new_value }}
											</span>
										</span>
										<span *ngSwitchDefault>
											{{ event | json }}
										</span>
									</ng-container>
								</div>
								<div class="text-xs leading-none">

								</div>
							</div>
							<div class="ml-auto text-2xl opacity-50">

							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngSwitchCase="'deviceEvent'">
						<div class="flex gap-2 text-2xl">
							<ng-container [ngSwitch]="event.deviceEvent_smartthings_attribute">
								<fa-icon *ngSwitchCase="'battery'" [icon]="['fas', 'battery-half']"></fa-icon>
								<fa-icon *ngSwitchCase="'motion'" [icon]="['fas', 'person-walking']"></fa-icon>
								<fa-icon *ngSwitchCase="'contact'" [icon]="['fas', 'door-open']"></fa-icon>
								<fa-icon *ngSwitchCase="'fire'" [icon]="['fas', 'fire-flame-curved']"></fa-icon>
								<fa-icon *ngSwitchCase="'water'" [icon]="['fas', 'droplet']"></fa-icon>
								<fa-icon *ngSwitchDefault [icon]="['fas', 'image']"></fa-icon>
							</ng-container>
						</div>
						<div class="flex flex-col gap-1">
							<div class="text-xs opacity-50 leading-none">
								{{ event.created_at | date:'HH:mm - dd.MM.yy' }}
							</div>
							<div class="leading-none">
								<span class="font-medium">
									{{ event.device_name ? event.device_name : event.device_third_party_name ? event.device_third_party_name : '-' }}
								</span>
								{{ event.deviceEvent_smartthings_attribute }}
								is
								<span class="font-semibold">{{ event.deviceEvent_value }}</span>
								<span class="font-semibold" *ngIf="event.unit"> event.deviceEvent_ </span>
							</div>
							<div class="text-xs leading-none">
								in
								{{ event.room_name ? event.room_name : event.room_third_party_name ? event.room_third_party_name : '-' }}
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="event.gatewayEvent_event == 'panic'">
				<div class="h-20 md:h-16 rounded-full flex px-5 py-2 items-center gap-4 overflow-hidden"
					[ngClass]="{'bg-red-600 text-white': event.gatewayEvent_new_value == 1, 'bg-gray': event.gatewayEvent_new_value == 0 }">
					<div class="flex gap-2 text-2xl">
						<fa-layers>
							<fa-icon [icon]="['fas', 'shield']"></fa-icon>
							<fa-icon [ngClass]="{'text-red-600' : event.gatewayEvent_new_value == 1, 'text-gray' : event.gatewayEvent_new_value == 0}"
								[icon]="['fas', 'exclamation']" transform="shrink-6"></fa-icon>
						</fa-layers>
					</div>
					<div class="flex flex-col gap-1">
						<div class="text-xs opacity-50 leading-none">
							{{ event.created_at | date:'HH:mm - dd.MM.yy' }}
						</div>
						<div class="leading-none">
							{{ event.gatewayEvent_new_value == 1 ? 'Panic active': 'Panic canceld' }}
						</div>
						<div class="text-xs leading-none">

						</div>
					</div>
					<div class="ml-auto text-2xl opacity-50">
						<a routerLink="/event">
							<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
						</a>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</div>




<!-- <a>
	<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
</a> -->
<!-- <fa-icon [icon]="['fas', 'unlock']"></fa-icon> -->
<!-- <fa-icon [icon]="['fas', 'house-lock']"></fa-icon> -->