import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@env/environment';
// import { Store } from '@ngxs/store';
import { User } from '../../state/user/user.actions';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

	private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

	/**
	 * Publishes `true` if and only if (a) all the asynchronous initial
	 * login calls have completed or errorred, and (b) the user ended up
	 * being authenticated.
	 *
	 * In essence, it combines:
	 *
	 * - the latest known state of whether the user is authorized
	 * - whether the ajax calls for initial log in have all been done
	 */
	public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
		this.isAuthenticated$,
		this.isDoneLoading$
	]).pipe(map(values => values.every(b => b)));

	constructor(
		private oauthService: OAuthService,
		private router: Router,
		// private store: Store,
		private storage: OAuthStorage
	) {
		// Useful for debugging:
		this.oauthService.events.subscribe(event => {
			if (event instanceof OAuthErrorEvent) {

				if (!environment.production && event.type == 'silent_refresh_error') {
					const keys = [
						'refresh_token',
						'expires_at',
						'nonce',
						'id_token_expires_at',
						'access_token_stored_at',
						'id_token_stored_at',
						'id_token',
						'access_token',
						'PKCE_verifier',
						'id_token_claims_obj',
						'session_state',
						'granted_scopes'];
					keys.forEach((k: string) => this.storage.removeItem(k));
				}

				console.error('OAuthErrorEvent Object:', event);
			} else {
				console.warn('OAuthEvent Object:', event);
			}
		});

		// This is tricky, as it might cause race conditions (where access_token is set in another
		// tab before everything is said and done there.
		// TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
		window.addEventListener('storage', (event) => {
			// The `key` is `null` if the event was caused by `.clear()`
			if (event.key !== 'access_token' && event.key !== null) {
				return;
			}

			console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

			if (!this.oauthService.hasValidAccessToken()) {
				this.login();
			}
		});

		this.oauthService.events.subscribe(_ => {
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
		});

		this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

		this.oauthService.events
			.pipe(filter(e => ['token_received'].includes(e.type)))
			.subscribe(e => this.oauthService.loadUserProfile());

		// this.oauthService.events
		// 	.pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
		// 	.subscribe(e => this.store.dispatch(new User.Fetch));

		this.oauthService.events
			.pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
			.subscribe(e => this.login());

		this.oauthService.setupAutomaticSilentRefresh();
	}

	public runInitialLoginSequence(): Promise<void> {
		// 0. LOAD CONFIG:
		// First we have to check to see how the IdServer is
		// currently configured:
		return this.oauthService.loadDiscoveryDocument()

			// 1. HASH LOGIN:
			// Try to log in via hash fragment after redirect back
			// from IdServer from initImplicitFlow:
			.then(() => {
				console.log('Discovery document loaded try login');
				return this.oauthService.tryLogin();
			})
			.then((status) => {
				console.log('status', status);
				console.log('this.oauthService.hasValidAccessToken()', this.oauthService.hasValidAccessToken());
				if (this.oauthService.hasValidAccessToken()) {
					return Promise.resolve();
				}


				// 2. SILENT LOGIN:
				// Try to log in via a refresh because then we can prevent
				// needing to redirect the user:
				console.log('silentRefresh');
				return this.oauthService.silentRefresh()
					.then(() => {
						console.log('silentRefresh success');
						return Promise.resolve()
					})
					.catch(result => {
						console.log('catch Result', result);
						// Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
						// Only the ones where it's reasonably sure that sending the
						// user to the IdServer will help.
						const errorResponsesRequiringUserInteraction = [
							'interaction_required',
							'login_required',
							'account_selection_required',
							'consent_required'
						];

						if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.params.error) >= 0) {

							// 3. ASK FOR LOGIN:
							// At this point we know for sure that we have to ask the
							// user to log in, so we redirect them to the IdServer to
							// enter credentials.
							//
							// Enable this to ALWAYS force a user to login.
							this.login();
							// Instead, we'll now do this:
							//console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
							//return Promise.resolve();
						}

						// We can't handle the truth, just pass on the problem to the
						// next handler.
						return Promise.reject(result);
					});
			})
			.then((result) => {
				console.log('Result', result);
				this.isDoneLoadingSubject$.next(true);

				// Check for the strings 'undefined' and 'null' just to be sure. Our current
				// login(...) should never have this, but in case someone ever calls
				// initImplicitFlow(undefined | null) this could happen.

				if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
					let stateUrl = this.oauthService.state;
					if (stateUrl.startsWith('/') === false) {
						stateUrl = decodeURIComponent(stateUrl);
					}
					console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
					this.router.navigateByUrl(stateUrl);
				}
			})
			.catch((error) => {
				console.log('Error', error);
				this.isDoneLoadingSubject$.next(true)
			});
	}

	public login(targetUrl?: string) {
		// Note: before version 9.1.0 of the library you needed to
		// call encodeURIComponent on the argument to the method.
		console.log(this.router.url);
		this.oauthService.initCodeFlow(targetUrl || this.router.url);
	}

	public logout() { this.oauthService.logOut(); }
}
