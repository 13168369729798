<div class="mb-4 flex">
	<h1 class="font-semibold text-2xl text-primary">
		<span class="">
			<a routerLink="..">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
			</a>
		</span>
		Devices
	</h1>
</div>
<section class="flex flex-col gap-4 mb-2">
	<div class="flex">
		<div class="ml-auto flex items-center gap-2">
			<p-button (onClick)="toggleFilterOptions()" styleClass="p-button-sm {{!showFilterOptions ? 'p-button-text' : ''}}">
				<fa-icon [icon]="['fas', 'filter']"></fa-icon>
				<span class="ml-2">Filter</span>
			</p-button>
			<div class="flex gap-2" *ngIf="filtersApplied > 0">
				<p-button (onClick)="resetFilterOption()" styleClass="p-button-text p-button-sm">
					<span>{{ filtersApplied }}</span>
					<span class="px-2">selected</span>
					<fa-icon [icon]="['fas', 'close']"></fa-icon>
				</p-button>
			</div>
		</div>
	</div>
	<div class="rounded-lg border border-gray p-2 md:p-4 flex gap-4 flex-col" *ngIf="showFilterOptions">
		<div class="flex-grow min-w-0">
			<h1 class="font-semibold">Filter</h1>
			<h3 class="text-xs">Filtering devices</h3>
		</div>
		<div class="flex gap-2 flex-wrap flex-grow min-w-0">
			<button class="flex h-9 rounded-full border px-4 gap-2 items-center disabled:opacity-50 text-sm md:text-base"
				[ngClass]="{'border-blue-600 bg-blue-200 text-blue-600': filter.active, 'border-gray': ! filter.active}"
				*ngFor="let filter of filters; trackBy:trackByIndex; let i = index" (click)="toggleFilterOption(i)" [disabled]="filter.disabled">
				<fa-icon *ngIf="filter.active" [icon]="['fas', 'check']"></fa-icon>
				<span>{{ filter.label }}</span>
			</button>
		</div>
	</div>
</section>
<div class="flex flex-col gap-4">
	<ng-container *ngIf="devicesLoading">
		<div class="h-20 rounded-full bg-gray flex px-4 py-2 items-center gap-4" *ngFor="let i of [].constructor(5)" @fadeInOut>
			<p-skeleton size="3rem" shape="circle"></p-skeleton>
			<div class="flex flex-col gap-1">
				<p-skeleton height=".75rem" width="6rem"></p-skeleton>
				<p-skeleton height="1rem" width="10rem"></p-skeleton>
				<p-skeleton height=".75rem" width="8rem"></p-skeleton>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!devicesLoading && devices.length == 0">
		<div class="flex flex-col p-3 mb-5 justify-center items-center" @fadeInOut>
			<fa-icon class="text-[64px] mb-2 opacity-20" [icon]="['fas', 'file-circle-exclamation']"></fa-icon>
			<h1 class="font-semibold text-lg">
				{{ (filtersApplied > 0) ? 'No matches' : 'No Devices yet' }}
			</h1>
		</div>
	</ng-container>
	<ng-container *ngIf="!devicesLoading && devices.length > 0">
		<div class="h-20 rounded-full bg-gray flex px-4 py-2 items-center gap-4" *ngFor="let device of devices"
			[ngClass]="{'bg-opacity-50': !device.online }" @fadeInOut>
			<div class="flex gap-2 text-2xl" [ngClass]="{'opacity-50': !device.online}">
				<ng-container [ngSwitch]="device.icon">
					<fa-icon *ngSwitchCase="'motion'" [icon]="['fas', 'person-walking']"></fa-icon>
					<fa-icon *ngSwitchCase="'contact'" [icon]="['fas', 'door-open']"></fa-icon>
					<fa-icon *ngSwitchCase="'smoke'" [icon]="['fas', 'fire-flame-curved']"></fa-icon>
					<fa-icon *ngSwitchCase="'water'" [icon]="['fas', 'droplet']"></fa-icon>
					<fa-icon *ngSwitchCase="'multi-sensor'" [icon]="['fas', 'layer-group']"></fa-icon>
					<fa-icon *ngSwitchDefault [icon]="['fas', 'image']"></fa-icon>
				</ng-container>
			</div>
			<div class="flex flex-col gap-1 justify-center">
				<div class="leading-none" [ngClass]="{'opacity-50': !device.online}">
					{{ device.name ? device.name : device.third_party.name ? device.third_party.name : '-'}}
				</div>
				<div class="text-xs leading-none" [ngClass]="{'opacity-50': !device.online}">
					{{ device.room ? device.room.name ? device.room.name : device.room.third_party.name ? device.room.third_party.name : '-' : '-'
					}}
				</div>
				<div class="text-xs leading-none flex items-center gap-4">
					<div class="flex items-center gap-1">
						<ng-container *ngIf="device.online">
							<span class="inline-block w-3 h-3 rounded-full bg-green-600"></span>
							<span>Online</span>
						</ng-container>
						<ng-container *ngIf="!device.online">
							<span class="inline-block w-3 h-3 rounded-full bg-red-600"></span>
							<span>Offline</span>
						</ng-container>
					</div>
					<ng-container *ngFor="let state of device.states" [ngSwitch]="state.type.smartthings_attribute">
						<div class="flex items-center gap-1" *ngSwitchCase="'battery'" [ngClass]="{'opacity-50': !device.online}">
							<span><fa-icon [icon]="['fas', 'battery']"></fa-icon></span>
							<span>{{ state.value }}</span>
							<span>{{ state.unit }}</span>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="ml-auto flex md:gap-4" [ngClass]="{'opacity-50': !device.online}">
				<ng-container *ngFor="let state of device.states" [ngSwitch]="state.type.smartthings_attribute">
					<div *ngSwitchCase="'contact'">
						<fa-icon *ngIf="device.icon == 'multi-sensor'" [icon]="['fas', 'door-open']" class="mr-2"
							[ngClass]="{'opacity-50 text-orange-300': (state.value == '' || state.value == null), 'opacity-50': state.value == 'closed' }"></fa-icon>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}" *ngIf="state.value == 'closed'">Closed</span>
						<span class="font-semibold" [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == 'open'">Open</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == '' || state.value == null">Unknow</span>
					</div>
					<div *ngSwitchCase="'motion'">
						<fa-icon *ngIf="device.icon == 'multi-sensor'" [icon]="['fas', 'person-walking']" class="mr-2"
							[ngClass]="{'opacity-50 text-orange-300': (state.value == '' || state.value == null), 'opacity-50': state.value == 'inactive' }"></fa-icon>
						<span class="font-semibold" [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == 'active'">Motion</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}" *ngIf="state.value == 'inactive'">Idle</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == '' || state.value == null">Unknow</span>
					</div>
					<div *ngSwitchCase="'smoke'">
						<fa-icon *ngIf="device.icon == 'multi-sensor'" [icon]="['fas', 'fire-flame-curved']" class="mr-2"
							[ngClass]="{'opacity-50 text-orange-300': (state.value == '' || state.value == null), 'opacity-50': state.value == 'clear' }"></fa-icon>
						<span class="font-semibold" [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == 'active'">Smoke</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}" *ngIf="state.value == 'clear'">Idle</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}" *ngIf="state.value == 'tested'">Tested</span>
						<span [ngClass]="{'hidden': device.icon == 'multi-sensor'}" *ngIf="state.value == '' || state.value == null">Unknow</span>
					</div>
					<div *ngSwitchCase="'water'">
						<fa-icon *ngIf="device.icon == 'multi-sensor'" [icon]="['fas', 'droplet']" class="mr-2"
							[ngClass]="{'opacity-50 text-orange-300': (state.value == '' || state.value == null), 'opacity-50': state.value == 'dry' }"></fa-icon>
						<span class="font-semibold" [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == 'wet'">Water</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}" *ngIf="state.value == 'dry'">Idle</span>
						<span [ngClass]="{'hidden md:inline-block': device.icon == 'multi-sensor'}"
							*ngIf="state.value == '' || state.value == null">Unknow</span>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>