import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { EventsComponent } from './events/events.component';
import { EventComponent } from './event/event.component';
import { SettingsComponent } from './settings/settings.component';

import { AuthGuard } from './shared/guards/auth/auth.guard';
import { DevicesComponent } from './devices/devices.component';

const routes: Routes = [
	{
		path: '', redirectTo: '/dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' }
	},
	{
		path: 'dashboard', component: DashboardComponent, data: { breadcrumb: { skip: true } }, canActivate: [AuthGuard]
	},
	{
		path: 'devices', component: DevicesComponent, data: { breadcrumb: 'Devices' }
	},
	{
		path: 'rules', loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule), canActivate: [AuthGuard]
	},
	{
		path: 'events', component: EventsComponent, data: { breadcrumb: 'Events' }, canActivate: [AuthGuard]
	},
	{
		path: 'event', component: EventComponent, data: { breadcrumb: 'Event' }, canActivate: [AuthGuard]
	},
	{
		path: 'integrations', loadChildren: () => import('./integrations/integrations.module').then(m => m.IntegrationsModule), canActivate: [AuthGuard]
	},
	{
		path: 'settings', component: SettingsComponent, data: { breadcrumb: 'Settings' }, canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
