import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Events } from './shared/state/events/events.actions';
import { Gateways } from './shared/state/gateway/gateway.actions';
import { Rules } from './shared/state/rule/rule.actions';
import { GatewayDevices } from './shared/state/gateway_devices/gateway_devices.actions';
import { AuthService } from './shared/services/auth/auth.service';
import { VersionService } from './shared/services/version/version.service';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	isAuthenticated: boolean = false;
	showNewVersionDialog: boolean = false;
	constructor(private store: Store, private authService: AuthService, private versionService: VersionService) { }

	ngOnInit(): void {

		// console.log('version', this.versionService.version);

		this.versionService.pollVersion();
		this.versionService.newVersion.subscribe((newVersion: boolean) => {
			console.log('new Version', newVersion);
			if (newVersion && !this.showNewVersionDialog) {
				this.showNewVersionDialog = true;
			};
		});

		this.authService.isAuthenticated$.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
		});

		this.store.dispatch(new Gateways.FetchAll).subscribe(() => {
			this.store.dispatch(new Events.Fetch);
			this.store.dispatch(new Rules.FetchForGateway);
			this.store.dispatch(new GatewayDevices.FetchForGateway);
		});
	}
}