import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class GatewayService {

	constructor(private apiService: ApiService) { }

	fetchGateways(options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways`, options);
	}

	fetchGatewaysEvents(options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/events`, options);
	}

	fetchDevicesForGateway(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/devices`, options);
	}

	fetchGatewayEvents(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/events`, options);
	}

	fetchGatewayEventsCombined(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/events/combined`, options);
	}

	fetchEventsForGatewayDevices(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/devices/events`, options);
	}

	fetchEventsForGatewayDevice(gateway_id: number, device_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/devices/${device_id}/events`, options);
	}


	fetchGraphsForGatewayDevice(gateway_id: number, device_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/devices/${device_id}/graph`, options);
	}

	updateGatewayPanic(gateway_id: number, panic: any, options?: any) {
		return this.apiService.put(`${environment.api_base_url}/gateways/${gateway_id}/panic`, panic, options);
	}

	fetchGatewayPanic(gateway_id: number, options?: any) {
		return this.apiService.get(`${environment.api_base_url}/gateways/${gateway_id}/panic`, options);
	}
}
