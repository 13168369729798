import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Observable, combineLatest, finalize } from 'rxjs'
import { Store, Select } from '@ngxs/store';

import { Gateway } from 'src/app/shared/state/gateway/gateway.model';
import { GatewayState } from 'src/app/shared/state/gateway/gateway.state';
import { Gateways } from 'src/app/shared/state/gateway/gateway.actions';
import { Events } from 'src/app/shared/state/events/events.actions';
import { GatewayDevices } from 'src/app/shared/state/gateway_devices/gateway_devices.actions';
import { Rules } from 'src/app/shared/state/rule/rule.actions';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { RuleState } from 'src/app/shared/state/rule/rule.state';
import { EventsState } from 'src/app/shared/state/events/events.state';
import { GatewaysEventsState } from 'src/app/shared/state/gateways_events/gateways_events.state';
import { GatewayDevicesState } from 'src/app/shared/state/gateway_devices/gateway_devices.state';
import { DeviceEventsState } from 'src/app/shared/state/device_events/device_events.state';
import { GatewaysEvents } from 'src/app/shared/state/gateways_events/gateways_events.actions';
import { DeviceEvents } from 'src/app/shared/state/device_events/device_events.actions';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


	isOpen: boolean = false;
	width: number = 0;

	@ViewChild('dropdown') dropdown!: ElementRef;
	@ViewChild('button') button!: ElementRef;

	@Select(GatewayState.getGateways) $gateways: Observable<Gateway[]>

	@Select(GatewayState.getSelecetdGateway) $selectedGateway: Observable<Gateway>
	gateways: Gateway[];

	gateway: Gateway;

	gateway_switching: boolean = false;

	constructor(private store: Store, private authService: AuthService) { } // private authService: AuthService

	ngOnInit(): void {
		// 	this.$user.subscribe((user: User) => this.user = user);

		this.$gateways.subscribe((gateways: Gateway[]) => this.gateways = gateways);
		this.$selectedGateway.subscribe((gateway: Gateway) => this.gateway = gateway);
	}

	gatewayChanged(event: any) {
		console.log('gatewayChanged', event);
		if (!event || !event.id) return;
		this.gateway_switching = true;

		this.store.dispatch(new Rules.Reset);
		this.store.dispatch(new GatewaysEvents.Reset);
		this.store.dispatch(new GatewayDevices.Reset);
		this.store.dispatch(new DeviceEvents.Reset);
		this.store.dispatch(new Events.Reset);

		this.store.dispatch(new Gateways.SelectGateway(event.id)).subscribe(() => {
			combineLatest([
				this.store.dispatch(new Events.Fetch),
				this.store.dispatch(new GatewayDevices.FetchForGateway),
				this.store.dispatch(new Rules.FetchForGateway)]
			).pipe(finalize(() => this.gateway_switching = false)).subscribe(() => {
				console.log('finished');
			});
		});
	}

	logout() {
		this.authService.logout();
	}

}
